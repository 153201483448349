import React,{useState} from 'react'
import '../App.css'
import {Row,Col,Form,Button} from 'react-bootstrap'

import background from "../assets/images/login-back-new.jpg";
type MyProps = {
    // using `interface` is also ok
    name: string;
  };
  type MyState = {
    count: number; // like this
  };


const login = (username:string,password:string) =>{
  alert(`username : ${username}
  password : ${password}
  `)
}


const LoginScreen = () =>{
  const [signInFlow,setSignInFlow] = useState(true)

  const [username,setUserName] = useState("")
  const [password,setPassword] = useState("")
  
  return(
    <Row className="fill-window" style={{backgroundImage:`url(${background})`}}>
      <Col><h1 className="text-white">Register your Approval</h1></Col>
      <Col md={5} className="shadow shadow-lg" style={{backgroundColor:"#FFFFFFf5"}}>
      <Form className="text-left mt-auto mb-auto">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="text" value={username} onChange={(ev)=>setUserName(ev.target.value)} placeholder="Enter email/Username" />
          <Form.Text className="text-muted">
          enter either your username or emailid
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" value={password} onChange={ev => setPassword(ev.target.value)} placeholder="Password" />
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button className="ml-auto mr-auto text-center" variant="primary" type="button" onClick={() =>login(username,password)}>
          Sign-In
        </Button>
      </Form>
      </Col>
    </Row>
  )
}

export default LoginScreen