import React from 'react'
import '../App.css'

type MyProps = {
    // using `interface` is also ok
    name: string;
  };
  type MyState = {
    count: number; // like this
  };
class HomePage extends React.Component<MyProps,MyState>{
    state: MyState = {
        // optional second annotation for better type inference
        count: 0,
      };
    render(){
        return(
            <div className="inner-app">
                <h1>Hello {this.props.name}</h1>
                <h3>This is Homepage</h3>
            </div>
        );
    }
}

export default HomePage